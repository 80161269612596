import type { AreaWithConsumption } from '~/types/area';

export const areaWithConsumption: AreaWithConsumption[] = [
  {
    id: 2,
    name: 'Châteaubriant',
    parent_id: null,
    consumption: 10000,
    drift: 0,
  },
  {
    id: 19,
    name: 'Compresseur',
    parent_id: 2,
    consumption: 2000,
    drift: 0,
  },
  {
    id: 20,
    name: 'Thermoformeuses',
    parent_id: 2,
    consumption: 8000,
    drift: 0,
  },
  {
    id: 23,
    name: 'TH21',
    parent_id: 20,
    consumption: 1000,
    drift: 227,
  },
  {
    id: 6,
    name: 'TH23',
    parent_id: 20,
    consumption: 3000,
    drift: 0,
  },
  {
    id: 22,
    name: 'TH11',
    parent_id: 20,
    consumption: 2000,
    drift: 0,
  },
  {
    id: 35,
    name: 'TH22',
    parent_id: 20,
    consumption: 1500,
    drift: 0,
  },
];
