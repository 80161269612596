<script lang="ts" setup>
import type { AreaWithConsumption } from '~/types/area';
import * as d3 from 'd3';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import colors from 'tailwindcss/colors';
import { ref, onMounted, onUnmounted, nextTick, watch } from 'vue';

// Types
export interface SankeyNode {
  name: string;
  value?: number;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
  id?: string | number;
}

// Props et Emits
const props = defineProps<{
  loading?: boolean;
  areaWithConsumption: AreaWithConsumption[];
}>();
const emit = defineEmits<{
  'node-click': [node: AreaWithConsumption];
}>();

// Data
const container = ref<HTMLDivElement | null>(null);
const width = ref(0);
const height = ref(0);

// Methods
const updateDimensions = () => {
  if (!container.value) return;
  const rect = container.value.getBoundingClientRect();
  width.value = rect.width || 1200;
  height.value = rect.height || 400;
};

const createChart = () => {
  if (!container.value || !props.areaWithConsumption.length || props.loading) return;

  updateDimensions();

  d3.select(container.value).selectAll('svg').remove();

  const svg = d3
    .select(container.value)
    .append('svg')
    .attr('width', width.value)
    .attr('height', height.value)
    .style('background-color', '#ffffff');

  const defs = svg.append('defs');
  const pattern = defs.append('pattern').attr('id', 'dots').attr('width', 20).attr('height', 20).attr('patternUnits', 'userSpaceOnUse');
  pattern.append('circle').attr('cx', 3).attr('cy', 3).attr('r', 1.5).attr('fill', colors.gray[200]);
  svg.append('rect').attr('width', width.value).attr('height', height.value).attr('fill', 'url(#dots)');

  const nodePadding = 20;

  const nodes = props.areaWithConsumption.map((area) => ({
    name: area.name,
    originalValue: area.consumption,
    value: area.consumption,
    id: area.id,
  }));

  const adjustNodeValues = () => {
    const nodesByLevel = new Map();
    nodes.forEach((node) => {
      const level =
        props.areaWithConsumption.find((a) => a.id === node.id)?.parent_id === null
          ? 0
          : props.areaWithConsumption.find((a) => a.id === props.areaWithConsumption.find((a) => a.id === node.id)?.parent_id)
                ?.parent_id === null
            ? 1
            : 2;
      if (!nodesByLevel.has(level)) nodesByLevel.set(level, []);
      nodesByLevel.get(level).push(node);
    });

    for (let level = Math.max(...nodesByLevel.keys()); level >= 0; level--) {
      const nodesInLevel = nodesByLevel.get(level) || [];

      nodesInLevel.forEach((node) => {
        const childrenIds = props.areaWithConsumption.filter((area) => area.parent_id === node.id).map((area) => area.id);

        if (childrenIds.length > 0) {
          const childrenSum = childrenIds.reduce((sum, childId) => {
            const child = nodes.find((n) => n.id === childId);
            return sum + (child?.value || 0);
          }, 0);

          const paddingValue = (childrenIds.length - 1) * nodePadding;

          node.value = childrenSum + paddingValue;
        }
      });
    }
  };

  adjustNodeValues();

  const links = props.areaWithConsumption
    .filter((area) => area.parent_id !== null)
    .map((area) => ({
      source: props.areaWithConsumption.findIndex((a) => a.id === area.parent_id),
      target: props.areaWithConsumption.findIndex((a) => a.id === area.id),
      value: nodes.find((n) => n.id === area.id)?.value || 0,
    }))
    .filter((link) => link.source !== -1 && link.target !== -1);

  const calculateTotalTextWidth = (nodes) => {
    const tempText = svg.append('text').style('visibility', 'hidden');
    let maxStackedWidth = 0;
    let maxInlineWidth = 0;

    nodes.forEach((node) => {
      tempText.style('font-size', '14px');
      tempText.text(node.name);
      const nameWidth = tempText.node()?.getBBox().width || 0;

      tempText.style('font-size', '12px');
      const area = props.areaWithConsumption.find((a) => a.id === node.id);
      tempText.text(`${area?.consumption || 0} kWh`);
      const consumptionWidth = tempText.node()?.getBBox().width || 0;

      let driftWidth = 0;
      if (area?.drift) {
        tempText.text(`+${area.drift}%`);
        driftWidth = tempText.node()?.getBBox().width + 16;
      }

      const stackedWidth = 16 + Math.max(nameWidth, consumptionWidth + (area?.drift ? 8 + driftWidth : 0)) + 16;
      maxStackedWidth = Math.max(maxStackedWidth, stackedWidth);

      const inlineWidth = 16 + nameWidth + 8 + consumptionWidth + (area?.drift ? 8 + driftWidth : 0) + 16;
      maxInlineWidth = Math.max(maxInlineWidth, inlineWidth);
    });

    tempText.remove();
    return Math.max(maxStackedWidth, maxInlineWidth) + 32;
  };

  const totalTextWidth = calculateTotalTextWidth(nodes);
  const sankeyGenerator = sankey<SankeyNode, any>()
    .nodeWidth(40)
    .nodePadding(nodePadding)
    .extent([
      [50, 50],
      [width.value - (totalTextWidth + 32), height.value - 50],
    ]);

  const graph = sankeyGenerator({
    nodes: nodes.map((d) => Object.assign({}, d)),
    links: links.map((d) => Object.assign({}, d)),
  });

  const firstNode = graph.nodes.find((node) => !graph.links.some((link) => link.target === node));

  const adjustNodeHeight = (node) => {
    const childLinks = graph.links.filter((link) => link.source === node);

    if (childLinks.length > 0) {
      let totalChildrenHeight = 0;
      let lastChildY1 = -Infinity;

      const sortedChildren = childLinks.map((link) => link.target).sort((a, b) => a.y0 - b.y0);

      sortedChildren.forEach((childNode) => {
        if (lastChildY1 !== -Infinity) {
          totalChildrenHeight += childNode.y0 - lastChildY1;
        }
        totalChildrenHeight += childNode.y1 - childNode.y0;
        lastChildY1 = childNode.y1;
      });

      const currentHeight = node.y1 - node.y0;
      if (totalChildrenHeight > currentHeight) {
        const diff = totalChildrenHeight - currentHeight;
        node.y1 += diff / 2;
        node.y0 -= diff / 2;
      }

      childLinks.forEach((link) => adjustNodeHeight(link.target));
    }
  };

  if (firstNode) {
    adjustNodeHeight(firstNode);
  }

  const hasDrift = (node) => {
    const area = props.areaWithConsumption.find((a) => a.id === node.id);
    return area?.drift || false;
  };

  const hasChildWithDrift = (node) => {
    const childrenIds = props.areaWithConsumption.filter((area) => area.parent_id === node.id).map((area) => area.id);
    return props.areaWithConsumption.some((area) => childrenIds.includes(area.id) && area.drift);
  };

  const shouldLinkBeRed = (link) => {
    const targetHasDrift = hasDrift(link.target);
    if (targetHasDrift) return true;

    const hasDescendantWithDrift = (node) => {
      const children = graph.links.filter((l) => l.source === node);
      return children.some((child) => hasDrift(child.target) || hasDescendantWithDrift(child.target));
    };

    return hasDescendantWithDrift(link.target);
  };

  graph.links.forEach((link, i) => {
    const gradientId = `linkGradient-${i}`;
    const gradient = defs
      .append('linearGradient')
      .attr('id', gradientId)
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', () => link.source.x1)
      .attr('x2', () => link.target.x0);

    const isLinkRed = shouldLinkBeRed(link);
    const color = isLinkRed ? '#EF4444' : colors.gray[200];

    gradient.append('stop').attr('offset', '0%').attr('stop-color', color).attr('stop-opacity', 0.4);

    gradient
      .append('stop')
      .attr('offset', '50%')
      .attr('stop-color', color)
      .attr('stop-opacity', isLinkRed ? 0.15 : 0);

    gradient.append('stop').attr('offset', '100%').attr('stop-color', color).attr('stop-opacity', 0.4);
  });

  svg
    .append('g')
    .selectAll('path')
    .data(graph.links)
    .join('path')
    .attr('d', sankeyLinkHorizontal())
    .attr('stroke', (d, i) => `url(#linkGradient-${i})`)
    .attr('stroke-width', (d) => Math.max(1, d.width))
    .attr('stroke-opacity', 1)
    .attr('fill', 'none');

  svg
    .append('g')
    .selectAll('rect')
    .data(graph.nodes)
    .join('rect')
    .attr('x', (d) => d.x0)
    .attr('y', (d) => d.y0)
    .attr('height', (d) => d.y1 - d.y0)
    .attr('width', (d) => d.x1 - d.x0)
    .attr('fill', (d) => {
      if (!graph.links.some((link) => link.target === d)) return colors.gray[50];
      return hasDrift(d) || hasChildWithDrift(d) ? '#FEF2F2' : colors.gray[50];
    })
    .attr('stroke', (d) => {
      if (!graph.links.some((link) => link.target === d)) return colors.gray[200];
      return hasDrift(d) || hasChildWithDrift(d) ? '#EF4444' : colors.gray[200];
    })
    .attr('stroke-width', 1)
    .attr('rx', 4)
    .attr('ry', 4)
    .style('cursor', 'pointer')
    .style('transition', 'fill 0.1s ease-in-out')
    .on('mouseover', function () {
      d3.select(this).attr('fill', (d) => {
        if (!graph.links.some((link) => link.target === d)) return colors.gray[100];
        return hasDrift(d) || hasChildWithDrift(d) ? '#FEE2E2' : colors.gray[100];
      });
    })
    .on('mouseout', function () {
      d3.select(this).attr('fill', (d) => {
        if (!graph.links.some((link) => link.target === d)) return colors.gray[50];
        return hasDrift(d) || hasChildWithDrift(d) ? '#FEF2F2' : colors.gray[50];
      });
    })
    .on('click', (event, d) => {
      const area = props.areaWithConsumption.find((a) => a.id === d.id);
      if (area) {
        emit('node-click', area);
      }
    });

  const MIN_HEIGHT_FOR_STACKED = 50;

  function getMaxNameWidth() {
    const tempText = svg.append('text').style('font-size', '14px').style('visibility', 'hidden');

    const maxWidth = Math.max(
      ...graph.nodes.map((node) => {
        tempText.text(node.name);
        return tempText.node()?.getBBox().width || 0;
      }),
    );

    tempText.remove();
    return maxWidth;
  }

  const maxNameWidth = getMaxNameWidth();

  svg
    .append('g')
    .selectAll('text')
    .data(graph.nodes)
    .join('text')
    .attr('x', (d) => d.x1 + 16)
    .attr('y', (d) => {
      const height = d.y1 - d.y0;
      const isLastChild = !graph.links.some((link) => link.source === d);
      return height < MIN_HEIGHT_FOR_STACKED || isLastChild ? (d.y1 + d.y0) / 2 : (d.y1 + d.y0) / 2;
    })
    .attr('dy', '0.35em')
    .attr('text-anchor', 'start')
    .attr('fill', colors.gray[700])
    .style('font-size', '14px')
    .text((d) => d.name);

  svg
    .append('g')
    .selectAll('text')
    .data(graph.nodes)
    .join('text')
    .attr('x', (d) => {
      const height = d.y1 - d.y0;
      const isLastChild = !graph.links.some((link) => link.source === d);
      return height < MIN_HEIGHT_FOR_STACKED || isLastChild ? d.x1 + 16 + maxNameWidth + 8 : d.x1 + 16;
    })
    .attr('y', (d) => {
      const height = d.y1 - d.y0;
      const isLastChild = !graph.links.some((link) => link.source === d);
      if (height < MIN_HEIGHT_FOR_STACKED || isLastChild) {
        return (d.y1 + d.y0) / 2;
      } else {
        return (d.y1 + d.y0) / 2 + 14;
      }
    })
    .attr('dy', '0.35em')
    .attr('text-anchor', 'start')
    .attr('fill', colors.gray[500])
    .style('font-size', '12px')
    .text((d) => {
      const originalArea = props.areaWithConsumption.find((area) => area.id === d.id);
      return `${originalArea?.consumption || 0} kWh`;
    });

  const getConsumptionWidth = (node) => {
    const tempText = svg.append('text').style('font-size', '12px').style('visibility', 'hidden');
    const originalArea = props.areaWithConsumption.find((area) => area.id === node.id);
    tempText.text(`${originalArea?.consumption || 0} kWh`);
    const width = tempText.node()?.getBBox().width || 0;
    tempText.remove();
    return width;
  };

  svg
    .append('g')
    .selectAll('g')
    .data(graph.nodes)
    .join('g')
    .each(function (d) {
      const area = props.areaWithConsumption.find((a) => a.id === d.id);
      if (area?.drift) {
        const g = d3.select(this);
        const height = d.y1 - d.y0;
        const isLastChild = !graph.links.some((link) => link.source === d);
        const consumptionWidth = getConsumptionWidth(d);

        const x =
          height < MIN_HEIGHT_FOR_STACKED || isLastChild
            ? d.x1 + 16 + maxNameWidth + 8 + consumptionWidth + 8
            : d.x1 + 16 + consumptionWidth + 8;

        const y = height < MIN_HEIGHT_FOR_STACKED || isLastChild ? (d.y1 + d.y0) / 2 : (d.y1 + d.y0) / 2 + 14;

        const text = g
          .append('text')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', '0.35em')
          .attr('text-anchor', 'start')
          .text(`+ ${area.drift} %`)
          .style('font-size', '12px');

        const bbox = text.node().getBBox();
        g.insert('rect', 'text')
          .attr('x', bbox.x - 4)
          .attr('y', bbox.y - 2)
          .attr('width', bbox.width + 8)
          .attr('height', bbox.height + 4)
          .attr('rx', 4)
          .attr('fill', '#FEF2F2');

        text.attr('fill', '#B91C1C');
      }
    });
};

const resizeObserver = new ResizeObserver(() => {
  updateDimensions();
  createChart();
});

// Lifecycle
watch(
  () => props.areaWithConsumption,
  () => {
    nextTick(() => {
      createChart();
    });
  },
  { immediate: true, deep: true },
);

onMounted(() => {
  if (container.value) {
    resizeObserver.observe(container.value);
    createChart();
  }
});

onUnmounted(() => {
  resizeObserver.disconnect();
});
</script>

<template>
  <div ref="container" class="w-full min-h-[400px]">
    <div v-if="loading" class="flex justify-center items-center min-h-[400px]">
      <ui-loader />
    </div>
  </div>
</template>

<style scoped>
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
</style>
